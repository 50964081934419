import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { ContactForm } from "../components/contact-form"

const ContactPage = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulContactPage {
            edges{
                node{
                   paragraph {
                    childMarkdownRemark {
                        html
                      }
                   }
                   email
                   addressStreet
                   addressCityStateZipCode
                }
            }
        },
      }`)
    const contactUsData = data.allContentfulContactPage.edges
    const contactUs = contactUsData[0].node
    return (
        <Layout>
            <Seo
                title="Contact Us - Iron West Group Affordable Heavy Equipment Sales"
                description="Contact Iron West Group if you have any questions or inqueries about the equipment that we sell or if you are thinking about purchasing something from our inventory."
                location={props.location.pathname}
                keywords={["Contact Iron West Group To Purchase Equipment"]}
            />
            <div className="bg-lightgray pt-16 pb-10 px-5 lg:py-16">
                <div className="container mx-auto">
                    <h1 className="text-center font-bold text-2xl">Contact Us Today</h1>
                    <div className="text-center py-4 pb-6 max-w-xl mx-auto" dangerouslySetInnerHTML={{
                        __html: contactUs.paragraph.childMarkdownRemark.html,
                    }}>
                    </div>
                    <div className="grid md:grid-cols-3 flex justify-center text-center pb-5">
                        <div className="mx-auto -mt-7 md:mx-0 flex justify-center xl:justify-end">
                            <div className="w-56">
                                <StaticImage src="../images/logo-trans.png" placeholder="none" loading="eager" alt="Iron West Group" title="Iron West Group" />
                            </div>
                        </div>

                        <div className="mx-auto pb-3 md:mx-0">
                            <p className="font-bold text-lg text-orange text-center pb-2">Email</p>
                            <a className="text-sm hover:text-orange hover:opacity-70 transition duration-100 ease-in-out" href="mailto:sales@ironwestgroup.com" title="Email Iron West Group" alt="Email Iron West Group">{contactUs.email}</a>
                        </div>

                        <div className="text-center mx-auto pb-3 md:mx-0 flex justify-center xl:justify-start">
                            <div>
                                <p className="font-bold text-lg text-orange text-center pb-2">Address</p>
                                <a className="text-sm hover:text-orange hover:opacity-70 transition duration-100 ease-in-out" href="https://www.google.com/maps/place/Iron+West+Group/@38.6583255,-121.3525257,15z/data=!4m5!3m4!1s0x0:0x32d1e50bcd69c68b!8m2!3d38.6583879!4d-121.3525248" target="_blank" rel="noreferrer" title="Visit Iron West Group" alt="Visit Iron West Group">{contactUs.addressStreet}
                                    <p className="hover:opacity-70">{contactUs.addressCityStateZipCode}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr className="border-orange max-w-3xl mx-auto pb-10" />
                    <ContactForm formName="Contact Page" />
                </div>
            </div>
        </Layout>
    )
}
export default ContactPage